.footer {
    width: 100%;
    height:auto;
    position: relative;
}

.footer-inner {
    background-image:linear-gradient(0deg, rgba(0, 0, 0, .6), rgba(0, 0, 0, .6)), url("../../../public/assets/images/sia-futsal-pitch-1.JPG");
    background-repeat: no-repeat;
    background-size: cover;
    padding: 180px 60px 20px 60px;
}

.footer-inner-flex{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.footer-logo-col {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 33%;
}

.quick-links-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 33%;
}

.socials-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 33%;
}

.title-container{
    color:white;
    font-family: 'Open Sans';
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 20px;
}

.asso-desc-container {
    color:white;
    font-family: 'Open Sans';
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 500;
}

.quick-links-container {
    display: flex;
    /* border: 2px solid red; */
    width: fit-content;
}

.quick-link-heading {
    color:white;
    font-family: 'Open Sans';
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 20px;
}

.all-quick-links {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.quick-link-links {
    color:white !important;
    font-family: 'Open Sans';
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 15px;
}

.sub-quick-link-links {
    color:white !important;
    font-family: 'Open Sans';
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 15px;
    /* margin-left: 10px; */
}


.social-icons-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.footer-seperator{
    background: #FFFFFF;
    mix-blend-mode: normal;
    opacity: 0.1;
    width: 80vw;
    position: relative;
    height: 2px;

}

.header-below-seperator{
    font-family: 'Open Sans';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 22px;
/* identical to box height */


color: #FFFFFF;

}

.socials-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}

.social-heading {
    color:white;
    font-family: 'Open Sans';
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 20px;
}

/* .tw-icon {
    background-image: url("../../../public/assets/icons/twitter.svg");
    cursor: pointer;
    width: 35px;
    height: 35px;
    background-size: 100%;
} */

.ig-icon {
    background-image: url("../../../public/assets/icons/ig.svg");
    cursor: pointer;
    width: 35px;
    height: 35px;
    background-size: 100%;
}

.fb-icon {
    background-image: url("../../../public/assets/icons/fb-new.svg");
    cursor: pointer;
    width: 35px;
    height: 35px;
    background-size: 100%;
    margin-right: 10px;
}

.reach-out-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    position: absolute;
    top: -75px;
    background-color: #1E73BE;
    width: 65%;
    height: auto;
    padding: 40px 50px;
}

.reach-out-container-inner{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
}


.call-section {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}

.call-details {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.call-text {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 15px;
    letter-spacing: 0.6px;
    color: #FFFFFF;
    margin-bottom: 15px;
}

.call-num {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 25px;
    letter-spacing: -0.204545px;
    color: rgba(255, 255, 255, 0.8);

}

.reach-out-container-holder {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.call-icon{
    background-image: url("../../../public/assets/icons/call.svg");
    cursor: pointer;
    width: 25px;
    background-repeat: no-repeat;
    height: 30px;
    background-size: 100%;
    margin-right: 15px;
}

.mail-icon {
    background-image: url("../../../public/assets/icons/mail.svg");
    cursor: pointer;
    width: 25px;
    height: 30px;
    background-repeat: no-repeat;
    background-size: 100%;
    margin-right: 15px;

}

.address-icon {
    background-image: url("../../../public/assets/icons/address.svg");
    cursor: pointer;
    width: 25px;
    height: 30px;
    background-repeat: no-repeat;
    background-size: 100%;
    margin-right: 15px;

}

.sia-logo {
    background-image: url("../../../public/images/logo.png");
    cursor: pointer;
    width: 100px;
    height: 55px;
    background-size: 100%;
    margin-right: 15px;
    margin-bottom: 15px;
}

.footer-inner-inner{
    display: none;
}

.follow_icons {
    display: none;
}

@media only screen and (max-width: 960px) { 

    
    .sub-quick-link-links {
        color:white !important;
        font-family: 'Open Sans';
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 15px;
        margin-left: 0px !important;
    }

    .reach-out-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #1E73BE;
        width: 100%;
        height: auto;
        padding: 40px 15px;
    }

    .reach-out-container-inner{
        flex-direction: column;
        width: fit-content;
    }

    .call-section {
        margin-bottom: 25px;
        /* justify-content: center;
        align-items: center; */
    }

    .quick-link-heading {
        text-align: center;
    }

    .social-heading  {
        width: 100%;
        text-align: center;
    }
    .call-details {
        /* justify-content: center;
        align-items: center; */
    } 

    .footer-inner {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-image:linear-gradient(0deg, rgba(0, 0, 0, .6), rgba(0, 0, 0, .6)), url("../../../public/assets/images/sia-futsal-pitch-1.JPG");
        background-repeat: no-repeat;
        background-size: cover;
        padding: 25px 15px;
    }
    

    .footer-logo-col {
        width: 95%;
        margin-top: 280px;
        justify-content: center;
        align-items: center;
        margin-bottom: 15px;
    }
    
    .title-container {
        text-align: center;
    }
    .asso-desc-container {
        text-align: center;
    }
    .footer-inner-flex{
        display: none;
    }
    
    .footer-inner{
        padding-left: 5px;
        padding-right: 5px;
    }
    .footer-inner-inner{
        width:80vw;
    }
    .footer-inner-inner{
        display: unset;
    }

    /* .bookings_follow_us_cont {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    } */

    .follow_icons {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
    }
}



.scrollToTop{
    display: flex;
    justify-content: flex-end;
    align-items: center;

}

.scrollToTop button{
    width: unset;
    height: unset;
    margin-top: unset;
    cursor: pointer;
}

.scrollToTop img{
    width: 20px;
    height: 20px;
    color: #FFFFFF;
}

.footer-inner-flex-column{
    display: flex;
    flex-direction: column;
}

.second_container{
    display: flex;
    justify-content: space-between;
    padding: 0px 10px !important;
}