@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');

.credentials input{
    width:30vw;
    border-radius: 10px;
    border: none!important;
    padding: 10px;
}

.credentials input:focus{
    border: none !important;
}

.credentials{
    margin: 20px;
}


.login-btn{
    color: black;
   
    border-radius: 20px;

    
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
}

@media only screen and (max-width:920px){
    .coming_soon{
        font-size: 50px !important;
    }
    .coming_soon_info{
        font-size: 15px !important;
    }
    .credentials input{
        width: 70vw;
    }
}