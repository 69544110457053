.basicFormRow{
    display: flex;
    gap: 20px;
    justify-content: space-between;
}


.besicFormInnerDiv{
    flex: 1;
    max-width: 550px;
}

@media (width < 580px) {
    .basicFormRow{
        flex-direction: column;
    }

}

