.Contact_us_Row{
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Col_1{
   background: #1E73BE;
   margin-left: 2rem;
   /* padding:5.6rem 3rem; */
   height: 35rem;
   padding-top: 40px;
   padding-left: 20px;
   padding-right: 20px;
   width: 40vw;
   color: white;
   text-align: center;
   display: flex;
   align-items: center;
   flex-direction: column;
   
}
.Col_2{
   
    width: 60%;
    
 }
.Col_1 div:nth-child(1){
    width: 5.988rem;
    height: 5.988rem;
}

.Col_1 div:nth-child(2) {
    margin-top: 3.7rem;
    margin-bottom: 1.5rem;
    font-weight: 600;
    font-size: 2.4rem;
}

.Col_1 div:nth-child(3) {
   
    font-weight: 400;
    font-size: 1.8rem;
}

.Col_1 div:nth-child(4) {
    margin-top:1rem;
    font-weight: 700;
    font-size: 2rem;
}

.send_us{
    margin-top: 7.2rem;
    margin-bottom: 10rem;
    margin-left: 12rem;
    margin-right: 12rem;
    padding-top: 3.9rem;
    padding-left: 10.2rem;
    padding-bottom: 3.9rem;
    padding-right: 8rem;
    width: 83vw;
    box-shadow: 0px .8rem 1.6rem rgba(0, 0, 0, 0.25);
    
}
.send_us{
    font-weight: 700;
    font-size: 2.4rem;
}
.input_to_send{
    display: flex;
    flex-direction: row;
    margin-top: 2.9rem;
    font-weight: 600;
    font-size: 1.6rem;
}
.input_to_send_text{
    font-size: 1.6rem;
}


.send_us form{
    font-weight: 600;
    font-size: 1.8rem;
}

.youmsg{
    width: 100%;
}

.send_us input{
        outline: 0;
        border-width: 0 0 .2rem;
        border-radius: 0;
        border-color: #D0D0D0;
        /* padding-bottom: 0px; */
        margin-bottom: 4.8rem;
        color: #272727;
}


.input_to_send div:nth-child(2){
    margin-left: 7rem;
}

.check{
    width: initial;
    height: initial;
    width: 13px !important;
}

.checkbox div{
 margin-top: -1rem; 
 margin-left: 1rem;  
 font-weight: 400;
 font-size: 1.4rem;
}

.checkbox{
 display: flex;
    
}

.header_new {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
  
  }
  
  .text {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 800;
    font-size: 45px;
    line-height: 25px;
    text-align: center;
    color: #000000;
    margin-bottom: 10px !important;
    margin-top: 20px !important;
  }
  
  .text2 {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    color: #C6C6C6;
    margin-top:-25px;
  }


@media only screen and (max-width: 1275px) {
    .Contact_us_Row{
        flex-direction: column;
        align-items: center;
        margin-left: 0px;
        margin:auto;
        
        
    }

    .Col_1{
        margin-bottom: 2rem;
        width: 85vw;
        margin-left: 0rem;
    }

    .input_to_send{
     flex-direction: column;
    }
 
    .contact-us-desc-2{
        width: 85% !important;
        margin-bottom: 20px;
    }

    .input_to_send div:nth-child(2){
        margin-left: initial;
    }
    
    .send_us{
      margin: initial;
      margin: auto;
      width: 85vw;
      padding-left: 2rem;
      padding-right: 2rem;
      margin-bottom: 9rem;
        
    }
    .input_to_send input{
        width: 90%;
        margin-bottom: 5rem;
        padding-top: initial
    }
    
    .youmsg{
        width: 80%;
        margin-bottom: initial;
    }
    
    
}