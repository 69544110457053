.team{
    padding: 20px;
    /* border: 1px solid #1877F2; */
    padding: initial;
    margin: 20px;

}



.advisiories_card{
    display:flex;
    justify-content:center;
    align-items:center;
    margin: auto;
    width:80vw;
    
}

.Advisiories_row{
    padding: 101px 121px;
    margin-bottom: 20px;
}

@media screen and (max-width: 1050px) {
    
    .Advisiories_row{
        padding: 20px 40px;
        margin-bottom: 80px;
    }
}