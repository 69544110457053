.Login_main{
    font-family: 'Open Sans';
    font-style: normal;
    overflow-x: hidden;
}

.Login_component_2{
    background: url(../../../public/assets/images/Rectangle\ 75.png) no-repeat center;
    background-size: cover;
    margin: 2rem 0;
    display: flex;
    justify-content: center;
    text-align: center;
    color: #ffffff;
    padding: 5rem;
    align-items: center;
    font-weight: 400;
    height: 30rem;
    font-size: 2rem;
    line-height: 3.8rem;
    flex-direction: column;
}

.login_component_1{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 710px;
    background-color:#FFE7EB; 
}

.login_information{
    background-color: #ffffff;
    padding: 2rem 4rem;
    width: 52.5rem;
    /* height: 56.8rem; */
}

.login_info_head{
    font-weight: 700;
    font-size: 2rem;
    margin-top: 2rem;
    margin-bottom: 3rem;
    line-height: 3rem;
}

.id , .pass{
    font-weight: 600;
    margin-bottom: 1rem;
    font-size: 1.4rem;
    line-height: 2.5rem;
}
.login-input{
    background: #FFFFFF;
    border: .1rem solid #8692A6;
    margin-bottom: 1.5rem;
    border-radius: .6rem;
    width: 42.6rem;
    height: 4rem;
    padding: 2rem;
}
/* input[type=text],input[type=password] {
    background: #FFFFFF;
    border: .1rem solid #8692A6;
    margin-bottom: 1.5rem;
    border-radius: .6rem;
    width: 42.6rem;
    height: 6.4rem;
    padding: 2rem;
} */

.signup{
    background: #1E73BE;
    padding: 5rem 5rem;
    width: 52rem;
    /* height: 56.8rem; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}


.log_in_btn{
    width: 42.6rem;
    height: 4rem;
    color: #ffffff;
    font-size: 1.8rem;
    margin-top: 2.5rem;
    cursor:pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.forgot{
    margin-top: 3rem;
    text-align: center;

}

.signup_head{
    font-weight: 700;
    font-size: 2.5rem;
    line-height: 4.1rem;
    color: #FFFFFF;
}

.signup_component{
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 2.4rem;
    /* margin-bottom: 23.4rem; */
    color: #FFFFFF;
    text-align: center;
}

.be_a_member_icon{
    padding: 25px 0px;
}

.member-icon {
    background-image: url("../../../public/assets/icons/add-user.svg");
    fill: #FFFFFF;
    width: 150px;
    height: 150px;
    background-size: 100%;
    background-repeat: no-repeat;
    padding: 25px 0px;
}


.signup_btn{
    
}

.signup_in_btn{
    width: 42.6rem;
    height: 6.4rem;
    background-color: #ffffff;
    color: #1E73BE;
    font-size: 1.8rem;
    margin-top: 2.5rem;
    cursor: pointer;
}
@media only screen and (max-width: 1100px) {
   
    .Login_component_2{
        margin: 0;
        
    }
    .login_component_1{
        flex-direction: column;
        height: 105vh;
    }
    .login_info_head{
        font-weight: 700;
        font-size: 2.4rem;
        line-height: 3.3rem;
    }
    .id , .pass{
        font-size: 1.4rem;
    }
    
    .signup{
        
/* height: 27.8rem; */
    }
    .signup_component{
        margin-bottom: initial;
        margin-bottom: 1rem;
    }
    .events_component_1{
        height: 30rem;
        padding-left: 10px;
    }
}