.sports_club{
    display: flex;
    margin-bottom: 20px;
}

.marginTopFromNavbar{
    margin-bottom: 120px;
    margin-top: 90px;
}

.sports_club button{
    width: initial;
    background-color: #ffffff;
    border: none;
    color:#F31237;
    font-weight: 800;
    font-size: 24px;
    line-height: 29px;
    cursor: pointer;
    /* or 120% */
    text-decoration-line: underline;
}

.SportsEvents{
    font-weight: 700;
    font-size: 32px;
    line-height: 44px;

    color: #000000;
}

.intro-text-header {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 2rem;
    text-transform: capitalize;
    font-feature-settings: 'liga' off;
    color: #161616;
    padding-bottom: 1.5rem;
    padding-top: 5rem;
}

.intro-text {
    
    padding-top: 2.5rem ;
    padding: 0 2rem;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    margin-bottom: 30px;
    text-align: center;
    max-width: 1024px;
}



.sports_availability{
    background: #EDFFF9;
    padding: 0px;
    margin-bottom: initial;
    border-radius: 6px;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    color: #00AB6F;

}
.FacilityEvent_main{
    padding: 0 !important;
    margin-bottom: 0px !important;
}

@media (width < 961px) {
    .marginTopFromNavbar{
        margin-bottom: 120px;
        margin-top: 0px;
    }
}

@media (width<500px) {
    .sports_availability{
        font-size: 15px;
    }
    .intro-text{
        padding: 1rem 2rem !important;
        
    }
    .FacilityEvent_main{
        padding: 0 !important;
        margin-bottom: 20px !important;
    }
    .Card_Events {
        padding: 0 !important;
    }
    .SportsEvents{
        font-size: 20px;
    }
}

