.recommended{
    color: #F31237;
    font-weight: 800;
    font-size: 2.4rem;
    line-height: 2.9rem;
    margin-bottom:4rem;

}
.Card_Events{
    padding: 2rem 12rem;
}

.FacilityEvent_main{
    display: flex;
    justify-content: space-around;
    /* margin-bottom: 1rem; */
    /* padding: unset !important; */
    /* padding: 2rem  !important; */
    padding-right: 3rem !important;
    
    
}

.FacilityEvent img{
    width: 100%;
    height: 40%;
}
.FacilityEvent{

    width: calc(49% - 50px);

    background: #FFFFFF;
    box-shadow: 0px .8rem 1.6rem rgba(0, 0, 0, 0.25);
    border-radius: .2rem;
}

.FacilityEvent_name{
    font-weight: 700;
    font-size: 3.2rem;
    line-height: 150%;
    /* or 48px */
    font-family: 'Open Sans';
font-style: normal;

font-size: 27px;
line-height: 150%;


    color: #12101C;
}

.FacilityEvent_desc{
    font-weight: 700;
    font-size: 1.4rem;
    line-height: 150%;
    /* or 24px */

    text-transform: uppercase;
    margin-bottom: 1.5rem;
    color: rgba(18, 16, 28, 0.6);

}

.FacilityEvent_desc_mini{
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #54595F;
}



.FacilityEvent_info{
    display: flex;
    justify-content: space-between;
    
}

.FacilityEvent_timings div:nth-child(1),.FacilityEvent_fees div:nth-child(1), .FaciltyEvent_booking_hrs div:nth-child(1){
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    /* or 27px */


    /* Heading Text */


    color: #12101C;
}

.events-info-timing{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    margin: .8rem 0px;
    line-height: 120%;
    color: #12101C;
    font-size: 14px;
}



.facilityEvent_book{
    border: none;
    width: 100%;
    cursor: pointer;
}


@media screen and (max-width: 900px) {
    .FacilityEvent_main{
        flex-direction: column;
        
    }
    .FacilityEvent{
        width: 100%;
        margin-bottom: 2rem;
     }
   
     .Card_Events{
        padding: 2rem;
     }
}

@media screen and (max-width: 500px){
    .Card_Events{
        overflow-x: hidden;
     }
     
    .FacilityEvent{
        margin-bottom: 60px;
    } 
}