.birth-section-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    /* align-items: end; */
    padding: 60px ;
    gap:60px;
}

.birth-image-container {
    /* padding: 50px 75px; */
}

.transformation_icon {
    background-image: url(/public/assets/icons/grow.png);
    width: 35px;
    height:auto;
}

.header_new {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  
  }
  
  .text {
    font-family: 'Lato';
    /* font-style: normal; */
    font-weight: 700;
    font-size: 45px;
    line-height: 68px;
    text-align: center;
    color: #000000;
    margin-bottom: 20px !important;
    margin-top: 20px !important;
  }
  
  .text2 {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    color: #C6C6C6;
    margin-top:-25px;
  }

.birth-img-holder {
    width: 40vw;
    aspect-ratio: 1/1;
    object-fit: contain;
}

/* .birth-text-holder {
    padding-left: 100px;
    margin-top: -125px;
} */

.marker-container{
    width:40vw;
    aspect-ratio: 1/1;
}

.line-clamp{
    display: -webkit-box;
    -webkit-line-clamp: 8;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.marker {
    background-image: url("../../../public/assets/icons/marker.svg");
    width: 95px;
    height: 110px;
    background-size: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: 100%;
}

.marker-date {
    position: absolute;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    color: #FFFFFF;
    text-align: center;
    
}

.history-title-holder {
    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 35px;
    color: #12101C;
    margin-top: 25px; 
    margin-bottom: 15px;
}

.history-title-holder-main {
    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 35px;
    color: #12101C;
    margin-top: 70px; 
    margin-bottom: 15px;
}



.red-highlight {
    width: 63px;
    height: 8px;
    left: 0px;
    top: 116px;
    background: #F31237;
    margin-bottom: 25px;
}

.birth-text {
    width: 90%;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    color: #54595F;
}


.events_component_1 {
    /* background: url("../../../public/images/eventbg.jpg") no-repeat center center;
    background-attachment: fixed;
    background-size: cover; */
    display: flex;
    justify-content: center;
    padding-top: 66px;
    padding-bottom: 66px;
    padding-left: 120px;
    align-items: flex-start;
    flex-direction: column;
    position: relative;
  }

  .events_component_1::after{
    content: '';
    position: absolute;
    background: url("../../../public/images/eventbg.jpg") no-repeat center center;
    background-size: 100% 250%;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index:-1;
  }

  .events_1 h1 {
    font-weight: 800;
    font-size: 56px;
    line-height: 76px;
    color: #ffffff;
  }

  .events_1 h1:hover,
.events_2:hover {
  text-decoration: underline;
  text-decoration-color: #1e73be;
  text-decoration-thickness: 5px;
}

.events_1 h1 {
    font-size: 30px;
  }



@media only screen and (max-width: 960px) {

    .marker {
        margin-top: 100px;
    }

    .birth-text-holder {
        padding: 25px 5px;
    }

    .history-title-holder {
        margin-top: 50px;
        text-align: center;
    }
/* 
    .about-us-images{
        padding-right: 30px !important;
    } */
    .birth-image-container {
        padding: 25px 5px;

    }

    .birth-section-container {
        align-items: center;
    }


    
    .about-us-all-img{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .about-us-all-images-first {
        
        margin-right: 10px;
        height: 60vh !important;
        width: 80vw;
        margin-bottom: 10px;
        display: none;
    }
    .about-us-all-images-second{
        
        display: none;
        width: 40vw;
        margin: initial !important;
        margin-bottom: 10px  !important;
    }
    .history-abous-us-images{
        display: flex;
        
    }
    .birth-section-container {
        flex-direction: column;
        padding-top: 40px;
        gap:unset;
    }

    .birth-text{
        text-align: center;
    }

    .birth-section-container-reverse{
        flex-direction: column-reverse;
    }
    .birth-img-holder {
       width: 80vw;
    }

    
    .marker-container{
        width:100vw;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        aspect-ratio: unset;
    }

    .line-clamp{
        display: -webkit-box;
        -webkit-line-clamp: 1000000;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .readMore{
        display: none;
        
    }
}