.main-upcoming{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 3;
    margin-top: 15px !important;
    margin-bottom: 60px;
    margin: auto;
}

.header_new {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  
  }
  
  .text {
    font-family: 'Playfair Dsiplay';
    /* font-style: normal; */
    font-weight: 700;
    font-size: 45px;
    line-height: 68px;
    text-align: center;
    color: #000000;
    margin-bottom: 20px !important;
    margin-top: 20px !important;
  }
  
  .text2 {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    color: #C6C6C6;
    margin-top:-25px;
  } 

  .gallery-imgs-row {
    margin-top: 10px;
  }

.eventBoxContainer{
    width: 33%;
}
.eventBoxContainer  img{
    width: 100%;
}

.header {
    font-family: 'Montserrat';
    font-size: 42px;
    font-weight: 700;
    line-height: 76px;
    margin-bottom: 18px;
    
}

.events{
    justify-content: center;
    align-items: center;
    display: flex;
    /* margin-top:20px; */
}
.image_Overlay{
    position: absolute;
    top:26px;
    z-index: 999;
    bottom: 26px;
    left:26px;
    right: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #F31237;
}

.event-1 , .event-2 , .event-3{
    margin: 7px;
}


.desc{
    position: absolute;
    text-align: center;
    top:400px;
    left:33px;
    right: 33px;
}

.calendar{
    background-color: #F31237;
    border-radius: 8px;
    text-align: center;
    font-family: 'Open Sans';
    font-style: normal;
    color: #FFFFFF;
    /* margin-right: 30px; */
    /* padding: 3px 0 8px 0; */
    padding: 3px 8px;
    align-self: flex-start;
}

.month{
    font-weight: 700;
    font-size: 11.3717px;
    line-height: 15px;
}

.date{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
}
.descriptions{
    font-family: 'Open Sans';
    font-style: normal;
    width: 220px;
    color: #12101C;
    align-self: flex-start;
}

.first{
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 20px;
}

.descriptions .first{
    color: #FFFFFF;
}

.second_desc{
    width: 220px;
}
.events_Image{
    object-fit:cover;
    width:20vw;
    
}

.upcoming-event-image:after {
    content: '\A';
    position: absolute;
    width: 100%; height:100%;
    top:0; left:0;
    background:linear-gradient(0deg, rgba(243, 18, 55, 0.5), rgba(243, 18, 55, 0.5));
    opacity: 0;
  
  }

  .upcoming-event-image:hover:after{
    opacity: 1;;
  } 
  .upcoming-event-image:hover .text-on-image{
    opacity: 1;
  }
  
  .text-on-image-btn button{
    background-color: transparent;
    border: 1px solid white;
    border-radius: 1rem;
    cursor: pointer;
  }
  .text-on-image-btn{
    text-align: center;
  }
@media only screen and (max-width: 960px) {
    .header{
        text-align: center;
    }
    .events {
        flex-direction: column;
    }
    .events_Image{
        width: 80vw;
    }
    
    .event-1,.event-2,.event-3 {
        margin-bottom: 50px;
    }
    .eventBoxContainer{
        width: 100%;
    }
    .eventBoxContainer  img{
        width: 100%;
    }
    .descriptions{
        width: fit-content;
    }
    .gallery-imgs-row {
        margin-top: 0px;
      }
}