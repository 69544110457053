.sportclubform .main{
    width: 100%;
    height: 1000px;
}

.sportclubform .main .form{
    width: 90%;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* background-color: red; */
}

.sportclubform .main .form .field{
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* width: 924px; */
    width: 92.4rem;
}

.sportclubform .main .form .memberfield{
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* width: 510px; */
    width: 51rem;
}

.sportclubform .main .form .memberfield div:nth-child(2){
    display: flex;
    flex-direction: column;
}

.sportclubform .main .form .memberfield div:nth-child(2) div{
    display: flex;
    flex-direction: row;
    /* width: 184px; */
    width: 18.4rem;
}

.sportclubform .main .form .memberfield div:nth-child(2) div input{
    /* margin-right: 20px; */
    margin-right: 2rem;
}

.sportclubform .main .form .field,.memberfield,.addressfield div{
    /* width: 109px; */
    /* height: 30px; */
    height: 3rem;
    
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    /* font-size: 20px; */
    font-size: 2rem;
    /* line-height: 30px; */
    line-height: 3rem;
    /* identical to box height, or 150% */
    
    
    color: #161616;    
}

.sportclubform .main .form input[type=text],select{
    /* width: 595px; */
    width: 59.5rem;
    /* height: 48px; */
    height: 4.8rem;
    background: #FFFFFF;
    /* border: 1px solid #E3E8EF; */
    border: .1rem solid #E3E8EF;
    /* border-radius: 4px; */
    border-radius: .4rem;
    transform: rotate(0.35deg);    
}

.sportclubform .main .form .addressfield{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    /* width: 924px; */
    width: 92.4rem;
}

.sportclubform .main .form .addressfield .addressboxes{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* height: 200px; */
    height: 20rem;
}

.sportclubform .main .form .addressfield .addressboxes div:nth-child(3){
    display: flex;
    justify-content: space-between;
}

.sportclubform .main .form .addressfield .addressboxes div:nth-child(3) input{
    width: 48%;
}

.sportclubform .main .form .consent{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.sportclubform .main .form .consent1{
    /* margin-top: 10px; */
    margin-top: 1rem;
}

.sportclubform .main .form .consent [type="checkbox"]:checked,[type="checkbox"]:checked,[type="checkbox"]:not(:checked){
    /* width: 16px; */
    width: 1.6rem;
    height: 16px;
    background: #1E73BE;
    border-radius: 4px;
    position: relative;
    top: -7px;
    margin-right: 10px;
}

.sportclubform .main .form .consent p{
    width: 100%;
    /* height: 26px; */
    height: 2.6rem;
    
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    /* font-size: 16px; */
    font-size: 1.6rem;
    line-height: 165%;
    /* or 26px */
    
    letter-spacing: 0.005em;
    
    color: #161616;
}


.sportclubform .main .form .submit{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* padding: 16px; */
    padding: 1.6rem;
    /* gap: 8px; */
    gap: .8rem;
    /* width: 468px; */
    width: 46.8rem;
    /* height: 55px; */
    height: 5.5rem;
    
    /* primary colour */
    
    background: #F31237;
    /* border-radius: 2px;     */
    border-radius: .2rem;    

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    /* font-size: 18px; */
    font-size: 1.8rem;
    line-height: 130%;
    /* or 23px */

    text-align: center;

    /* Screen Color */

    color: #FFFFFF;

    cursor: pointer;
}

.sportclubform{
    overflow-x: hidden !important;
  }

/* mobile responsiveness */
@media screen and (max-width : 895px){
    .sportclubform .main .form{
        flex-direction: column;
    }

    .sportclubform .main .form .memberfield{
        flex-direction: column;
        align-items: flex-start;
    }
    
    .sportclubform .main .form .field{
        flex-direction: column;
        align-items: start;
    }

    .sportclubform .main .form .addressfield{
        flex-direction: column;
        align-items: start;
    }
}

@media screen and (max-width : 510px){
    .sportclubform .main .form input[type=text], select{
        width: 50rem;
    }
    
    .sportclubform .main .form .addressfield .addressboxes div:nth-child(3){
        flex-direction: column;
    }

    .sportclubform .main .form .addressfield .addressboxes div:nth-child(3) input{
        width: 100%;
    }

    .sportclubform .main .form .consent1{
        position: relative;
        top: 30px;
    }
}

@media screen and (max-width : 400px){
    .sportclubform .main .form input[type=text], select{
        width: 40rem;
    }
}