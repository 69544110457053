.membershipFormButton{
    background-color: #F31237;
    border: none;
    margin-bottom: 120px;
    color: white;
    padding:10px 36px;
    font-weight: 600;
    outline: none;
    margin-bottom: 120px;
}

.membershipFormButton:focus{
    outline: none;
}
.membershipFormButton.previousBtn{
    background-color: white;
    color: black;
    border:1px solid #E3E8EF;
}

.membershipFormButton:hover{
    background-color: #F31237;
    color: white;
}
.membershipFormButton.previousBtn:hover{
    background-color: white;
    color: black;
}