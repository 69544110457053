
  .heroContainer{
    position: relative;
    top: 90px;
    margin-bottom: 60px;
    height: calc(100vh - 90px);
    overflow: hidden;
  }




  .heroImg{
    width: 100%;
    height: calc(100vh + 90px);
    object-fit: cover;
  }

  .heroContentWrapper{
    position: absolute;
    top: 50%;
    /* border: 4px solid red; */
    left: 50%;
    width: 100%;
    /* margin: auto; */
    height: 100%;;
    transform: translateX(-50%) translateY(-50%);
    display: flex;
    justify-content: center;
    /* height: calc(100% + 90px); */
    background-color: black;
    /* width: 90%; */
    flex-direction: column;
    align-items: center;
  }

  .heroText{
    font-size: 9rem;
    padding: 0;
    margin: 0;

    text-align: center;
    line-height: 10rem;
    color: white;

    font-weight: 700;
  }

  .overlayHero{
    position: absolute;
    top: 40%;
    left: 50%;
    width: 100%;
    /* margin: auto; */
    transform: translateX(-50%) translateY(-50%);
    display: flex;
    z-index: 10;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .heroBtn{
    width: 200px;
    margin-top: 50px;
    text-align: center;
    padding: 20px 10px;
    color: white;
    font-size: 20px;
    font-weight: 500;
    background-color: #F31237;
    transition: all 0.4s;
    border-radius: 1px;
    min-width: max-content;
  }

  .heroBtn:hover{
    color: #12101c;
    background-color: #fff;
  }


  .heroImgContainer{
    transition: all 10s;
    height: max-content;
    background-color: black;
    overflow: hidden;
  }
  .heroImgContainer:hover{
    /* transform: scale(1.1); */
    background-color: transparent;
  }


  .heroSwiper .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets.swiper-pagination-horizontal{
    bottom: 250px;
    z-index: 10;
  }

  .heroSwiper .swiper-pagination-bullet.swiper-pagination-bullet-active{
    transform: scale(1.6);
    background-color: yellow !important;
  }
  .heroSwiper .swiper-pagination-bullet{
    background-color: white !important;
    opacity: 1 !important;
  }


 


@media (width< 1100px) {
  .heroContainer{
    height: fit-content;
  }
  .heroImg{
    height: calc(90vh - 90px);
  }
  .heroContentWrapper,.overlayHero{
    top: 50%;
  }

  .heroSwiper .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets.swiper-pagination-horizontal{
    bottom: 50px;
  }

  .heroText{
    font-size: 7rem;
    line-height: 9rem;
  }
  .heroBtn{
    margin-top: 20px;
  }
  
}

@media (width< 800px) {

  .heroImg{
    height: calc(70vh - 90px);
  }

  .heroSwiper .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets.swiper-pagination-horizontal{
    bottom: 40px;
  }
  .heroBtn{
    width:100px;
    padding: 12px 18px;
    font-size: 16px;
  }
}

@media (width< 500px) {
  .heroContainer{
    height: fit-content;
  }
  .heroText{
    font-size: 4rem;
    line-height: 5rem;
  }

  .heroBtn{
    width:100px;
    padding: 10px 15px;
    font-size: 14px;
  }

  .heroImg{
    height: calc(45vh - 90px);
  }
  .heroSwiper .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets.swiper-pagination-horizontal{
    bottom: 20px;
  }
}


@media (width< 960px) {
  .heroContainer{
    top: 0px;
    height: fit-content;
  }
  
}



