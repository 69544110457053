.events_component_1 {
  /* background: url("../../../public/images/eventbg.jpg") no-repeat center center fixed;
  background-position: center center;
  background-size: cover; */
  display: flex;
  justify-content: center;
  padding-top: 32px;
  padding-bottom: 32px;
  padding-left: 120px;

  align-items: flex-start;
  flex-direction: column;
}

.header_new {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;

}

.text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  font-size: 45px;
  line-height: 68px;
  text-align: center;
  color: #000000;
  margin-bottom: 20px !important;
  margin-top: 20px !important;
}

.text2 {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  color: #C6C6C6;
  margin-top:-25px;
} 



.events_1 h1 {
  font-weight: 800;
  font-size: 56px;
  line-height: 76px;
  color: #ffffff;
}

.small_img_folder{
  width: 100%;
  object-fit: cover;
  height:100%;
}

.small_img{
  height: 40vh;
}

.image-gallery-folder{
  position: relative;
  
  height: 600px;
}

.image-gallery-folder:after {
  content: '\A';
  position: absolute;
  width: 100%; height:100%;
  top:0; left:0;
  background:rgba(0,0,0,0.6);
  opacity: 1;

}

/* .image-gallery-folder:hover:after {
  opacity: 1;
} */

.gallery-imgs-row-galleryFolder{
  padding-left: 50px;
  padding-right: 50px;
}

.text-on-image{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color:#fff;
  /* font-size:14px; */
  opacity: 1;
  z-index: 99;
}

.text-on-image .text-title{
  font-family: 'Open Sans';
font-style: normal;
text-align: center;
font-weight: 700;
font-size: 20px;
white-space: nowrap;
}

.galleryHeading{
  font-family: 'Open Sans';
font-style: normal;
font-weight: 700;
font-size: 50px;
text-align: center;
margin-top: 35px;
}

.text-on-image .text-date{
  font-family: 'Open Sans';
font-style: normal;
text-align: center;
font-weight: 400;
font-size: 17px;

}

/* .image-gallery-folder:hover .text-on-image{
  opacity: 1;
} */

.image_Overlays{
  position: absolute;
  top:26px;
  z-index: 999;
  bottom: 26px;
  left:26px;
  right: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 7px solid #F31237;
  opacity: 1;
}

/* .image-gallery-folder:hover .image_Overlays{
  opacity: 1;
} */



/* .text-on-image:hover{
  display: unset ;
} */

.events_1 h1:hover,
.events_2:hover {
text-decoration: underline;
text-decoration-color: #1e73be;
text-decoration-thickness: 5px;
}

.events_1 h1 {
    font-size: 30px;
}

.gallery_main {
  padding: 0px 50px;
  padding-bottom: 150px;
  max-width: 85vw;
}

.img-container {
  padding: 0px 15px;
}

.gallery-container {
display: flex;
justify-content: center;
align-items: center;
}

@media screen and (max-width: 1100px) {
  .events_1 h1 {
    font-size: 27px;
}


}

@media screen and (max-width: 700px) {

  .text-title{
    font-size:13px;
    font-weight: 200; 

  }
  .image_Overlays{
    display: none;
  }

.galleryHeading{
  font-weight: 600;
font-size: 30px;
}

.gallery-imgs-row-galleryFolder{
  padding-left: 0px;
  padding-right: 0px;
}
.gallery_main{
  padding: 20px 20px;
  padding-bottom: 150px;
}

}