.main {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    /* margin-bottom: 70px;
    margin-top: 80px; */
    padding: 80px 80px;
    position: relative;
    z-index: 1;
}

.component {
    display: flex;
    max-width: 100%;
}

.component .components_Img {
   
    width: 50%;
    
    height: auto;
   
}

.components_Img img{
    object-fit: cover;
    width:100%;
   height: 100%;
}

.content {
    padding: 24px 81px;
    font-family: 'Open Sans';
    font-style: normal;
    background-image: url("../../../public/images/dark-textured-background_1017-1900.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 50%;
}

.content hr {
    border: 0px solid #F31237;
    background-color: #F31237;
    width: 63px;
    height: 8px;
    margin: 5px 0px 15px 0px;
}

.content-1 {
    color: #FFFFFF;
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 30px;
    line-height: 54px;
    padding: 10px 0px 10px 0px;
}

.content-2 {
    color: #F31237;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    font-family: 'Montserrat';

}

.content-3 {
    color: #FFFFFF;
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    margin-top: 10px;
}

.content-4 {
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    margin-top: 10px;
    color: #FFFFFF;
}

button {
    margin-top: 20px;
    color: #FFFFFF;
    background-color: #F31237;
    padding: 16px;
    font-weight: 700;
    line-height: 130%;
    border: none;
}

.curve-holder {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    position: absolute;
    z-index: 9999;
    top: 200px;
    left: 0;
  }
  
  /* .curve {
    width: 100vw;

  } */

  .shape_test {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    position:absolute;
    top: 580px;
  }

@media only screen and (max-width: 960px) {
    .component {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .main{
        padding: unset;
    }
    .image {
        display: flex;
        margin: 20px 0;
    }

    .image img {
        height: 430px;
        width: auto;
    }

    .component_Img{
        width: 100%;
    }

    .content {
        text-align: center;
        padding: 24px 24px;
        width: 100%;
    }

    .component {
        max-width: 100vw;
    }

    .content-2 {
        margin-top: 10px;
    }

    .content hr {
        display: flex;
        margin: auto;
    }
    .components_Img{
        width: 100vw !important;
    }
    .components_Img img {
       
        width: 100%;
        height: unset;
    }

    .curve-holder {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100vw;
        position: absolute;
        z-index: 9999;
        /* top: 200px; */
        left: 0;
      }
      
      /* .curve {
        width: 100vw;
    
      } */
    
      .shape_test {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100vw;
        position:absolute;
        top: 580px;
      }
}