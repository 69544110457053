li{
    list-style: none;
}

a{
    text-decoration: none;
    
}

.nav-item a{
    color:#12101C;
}

.dropdown-menu {
    background-color: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    padding: 15px 10px;
}

.navbar_desk_container {
    display: flex;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999;
    background-color: #FFFFFF;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.055);

}
.navbar_main {
    display: flex;
    width: 100vw;
    justify-content: space-around;
    align-items: center;
    font-family: 'Open Sans';
    font-style: normal;
}

.middle_navbar {
    flex-grow: 0.4;
    font-size: 18px;
    font-weight: 700;
}

.middle_navbar_component {
    display: flex;
    justify-content: space-around;
    text-decoration: none;
    align-items: center;
    font-size: 14px;
}

.middle_navbar_component .dropdropicon{
    position: relative;
    top: -3px;
    margin-left: 2px;
}

.middle_navbar_component .dropdown-item{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 30px;
    color: #12101C;    

}

.middle_navbar_component .dropdown-item:hover,.nav-link:hover{
    color: #1E73BE;
}

.nav-link:hover path{
    fill:#1E73BE;
}

.userloggedIn div{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 120px;
}

.userloggedIn div span{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 125%;
    /* identical to box height, or 22px */


    /* secondary */

    color: #1E73BE;
}

.link {
    text-decoration: none;
    color: #12101C;
    /* font-size: 14px; */
}

.link:hover {
    /* border-bottom: 3px solid #1E73BE; */
}

.bookings_tab{
    color:#1E73BE !important;
}

.btn {
    padding-bottom: 20px;
    cursor: pointer;
}

.btn button {
    border: none;
}

.logo {
    width: 120px;
}

.navbar_main.mobile {
    justify-content: space-between;
    align-items: center;
    padding-right: 8px;
}

.navbar_main.mobile button {
    margin-top: 0px;
}


@media only screen and (min-width: 960px) {
    .mobile {
        display: none;
    }
}

@media only screen and (max-width: 960px) {
    .desktop {
        display: none;
    }
    .mobile{
        padding: 11px 8px 8px 10px;
    }
    .dropdown-menu{
        transform: translate3d(32vw, 32px, 0px) !important;
         
        }

    }

@media only screen and (max-width: 1100px) {
    .middle_navbar_component {
        font-size: 12px;
    }

    .btn button {
        font-size: 10px;
    }
}

@media screen and (min-width: 960px) {
    .dropdown:hover .dropdown-menu {
      display: block;
    }
    .dropdown .dropdown-menu {
      display: none;
    }
  }