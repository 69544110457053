.events_main {
  font-family: "Open Sans";
  font-style: normal;
  width: 100vw;
  margin-bottom: 160px;

}

.events {
  padding-bottom: 60px;
}

.header_new {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;

}

.text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  font-size: 45px;
  line-height: 68px;
  text-align: center;
  color: #000000;
  margin-bottom: 20px !important;
  margin-top: 20px !important;
}

.text2 {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  color: #C6C6C6;
  margin-top:-25px;
}

.events_component_1 {
  /* background: url("../../../public/images/Rectangle 64.png") no-repeat center center fixed;
  background-position: center center;
  background-size: cover; */
  display: flex;
  justify-content: center;
  padding-top: 66px;
  padding-bottom: 66px;
  padding-left: 120px;

  align-items: flex-start;
  flex-direction: column;
}

.events_1 h1 {
  font-weight: 800;
  font-size: 56px;
  line-height: 76px;
  color: #ffffff;
}

.events_1 h1:hover,
.events_2:hover {
  text-decoration: underline;
  text-decoration-color: #1e73be;
  text-decoration-thickness: 5px;
}

.events_2 {
  font-weight: 700;
  font-size: 24px;
  line-height: 180%;
  color: #c6c6c6;
}

.second hr {
  text-align: left;
}

.events_component_2 {
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  /* align-items: center; */
  padding-top: 80px;
  padding-bottom: 80px;
  padding-left: 150px;
  background-color: #ffe7eb;
  align-items: center;

}

.first img {
  width: 70%;
  margin-top: 20px;
}

.second {
  background-color: #ffffff;
}

.second {
  position: absolute;
  left: 50vw;

  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-around;

  padding: 30px 60px 10px 50px;
  width: 40%;
}

.second .head {
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: 0.02em;
  font-feature-settings: "liga" off;
  color: #54595f;
}

.head_2 {
  font-weight: 800;
  font-size: 25px;
  line-height: 30px;
  letter-spacing: 0.01em;
  font-feature-settings: "liga" off;

  color: #12101c;
}

.second hr {
  border: 3px solid #f31237;
  background-color: #f31237;
  width: 63px;
  height: 2px;
  margin: 5px 0px 10px 0px;
}
.head_2 {
  width: 444px;
}
.description_events_2 {
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  /* or 178% */

  font-feature-settings: "liga" off;

  color: #54595f;
}

.when_where {
  display: flex;
  margin-top: 20px;
 width: 100%;

  padding-right: unset;
  margin-right: unset;
  justify-content: space-between;

}

.when{
  flex: 1 1 0px;
}
.where{
  flex: 1 1 0px;
}

.btn{
  padding: unset;
  margin-bottom: 20px;
}
.where span,
.when span {
  font-size: 18px;
  line-height: 32px;
  font-weight: 700;
}
/* .where div {
 
} */

.about-events{
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  width: 95%;
  /* or 160% */

font-feature-settings: 'liga' off;

color: #54595F
}
.main-component-of-event{
  padding-left: 82px;
  padding-right: 62px;
  margin-top: 20px;
  margin-bottom: 100px;
}

.main-about-events{
  margin-top: 60px;

}

.points-about-events{
font-family: 'Open Sans';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;
/* identical to box height, or 160% */
padding-left: 20px;
font-feature-settings: 'liga' off;
color: #54595F;
}

.points-about-events li{
  list-style:auto;
  margin: 31px 0px;
}

.events_header{
  text-align: center;
}

@media only screen and (max-width: 1186px) {
  .first {
    display: flex;
    /* justify-content: center; */
    align-items: center;
  }

  .first img {
    width: 380px;
  }

  .second {
    position: static;
    text-align: center;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    margin-top: -20px;
    width: 70vw;
  }

  .events_component_1 {
    text-align: center;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .events_component_2 {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
    height: 170vh;
    background-color: #ffffff;
  }

  .second hr {
    width: 63px;
    height: 0.5px;
  }
  .head {
    font-size: 16px;
    line-height: 19px;
  }
  .head_2 {
    font-size: 24px;
    line-height: 120%;
    width: 300px;
    margin-bottom: 10px;
    margin-top: 30px;
  }
  .description_events_2 {
    font-size: 14px;
    width: 400px;
    padding: 10px 10px;
  }

  .when_where {
    font-size: 14px;
    justify-content: space-around;
    /* width: 100%; */
  }
  .when_where span {
    font-size: 16px;
  }
  .events_1 h1 {
    font-size: 30px;
  }
  .events_2 {
    font-size: 12px;
  }
}

@media only screen and (max-width: 960px) {
  
  .second
  {
    width: 100vw;
    padding: 10px 15px;
  }

  .when_where {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .events_component_2 {
    height: fit-content;
  }

  .when_where{
    flex-direction: row;
    width: 90vw;
    align-items: flex-start;
    justify-content: space-between;
  
  }
  .main-component-of-event{
    padding-left: 24px;
    padding-right: 20px;
    margin-top: 10px;
    margin-bottom: 40px;
  }
  .main-about-events{
    margin-top: 30px;
  }

}

