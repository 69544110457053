.eventsFacilityBookingCard {
    display: flex;
    padding: 5rem 15rem;
    margin-bottom: 4rem;

}

.events_amenties_card {
    display: flex;
    flex-direction: row;
}

.events_amenties {
    font-weight: 700;
    font-size: 2.4rem;
    line-height: 3.2rem;
    margin-bottom: 3.8rem;
    margin-top: 4.8rem;
    /* identical to box height, or 133% */
}

.events_amenties_one div,
.events_amenties_two div {
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 2.4rem;
    margin-bottom: 3rem;

    /* identical to box height, or 150% */

    display: flex;
    align-items: center;

    /* Heading Text */

    color: #12101C;
}

.events_amenties_one {
    margin-right: 4.8rem;
}

.events_amenties_card img {
    margin-right: 2rem;
}


.EventsFacilityBooking_card_two {
    background: #FFFFFF;
    padding: 2.4rem;
    /* height: 530px; */
    height: 57rem;
    /* gray/200 */

    border: .1rem solid #E5E7EB;
    /* shadow-xl */
    width: 40%;

    box-shadow: 0px 2rem 2.5rem rgba(31, 41, 55, 0.1), 0px 10px 10px rgba(31, 41, 55, 0.04);
    border-radius: .8rem;
}

.EventsFacilityBooking_card_one {
    width: 60%;
    margin-right: 4rem;
}

.SportsEvents_info {
    border-top: .1rem solid #BCD7F7;
    border-bottom: .1rem solid #BCD7F7;
    padding: 3rem 0px;
}

.SportsEvents_info_head {
    font-weight: 700;
    font-size: 2.4rem;
    line-height: 3.2rem;
}

.timing_flex_main {
    border-bottom: .1rem solid #E5E7EB;
    margin-bottom: 2rem;
    padding-bottom: 2rem;
}

.EventsFacilityBooking_card_two_head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
}

.EventsFacilityBooking_card_two_head div:nth-child(1) {
    font-weight: 700;
    font-size: 2rem;
    line-height: 1.7rem;
}

.EventsFacilityBooking_card_two_head div:nth-child(2) span {
    font-weight: 700;
    font-size: 2rem;
    line-height: 3.2rem;
    /* identical to box height, or 160% */



    /* secondary */

    color: #1E73BE;
}

.EventsFacilityBooking_card_two_head div:nth-child(2) {
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.4rem;
    /* or 150% */

    display: flex;
    align-items: center;

    /* body text */

    color: #54595F;
}

.EventsFacilityBooking_card_two_input {
    padding: .4rem;
    /* white */

    background: #FFFFFF;
    border: 1px solid #BCD7F6;
    margin-bottom: 2.8rem;
    border-radius: .8rem;
}

.Card_two_input {
    justify-content: space-between;
    display: flex;
}

.card_two_input_one div {
    margin-bottom: 1rem;
}

.card_two_input_one label {
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 1.6rem;
    /* identical to box height, or 114% */



    /* Heading Text */

    color: #12101C;
}

.card_two_input_one select {
    border: none;
    width: 100%;

}

.card_two_input_one select:focus {

    outline: none;

}

.card_input_margin {
    margin-top: 2rem;
}




.timing_flex {
    display: flex;
    justify-content: space-between;
}

.timing_flex .left {
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.4rem;
    /* identical to box height, or 150% */

    display: flex;
    align-items: center;

    /* Heading Text */
    margin-bottom: .8rem;

    color: #12101C;
}

.timing_flex .right {
    font-weight: 700;
    margin-bottom: .8rem;
    font-size: 1.6rem;
    line-height: 2.4rem;
    /* identical to box height, or 150% */

    display: flex;
    align-items: center;
    text-align: right;

    /* Heading Text */

    color: #12101C;
}


.facility_booking_btn {
    width: 100%;
    border: none;
}

.facility_booking_map img {
    width: 172%;
}



.events_facility_desc {
    margin-bottom: 2rem;
}

.FacilityEvent_info {
    margin-bottom: 2rem;
}

.FacilityEvent {
    height: unset;
    margin: 25px !important;
}

.card-col {
    padding: 6rem;
}

.content-holder {
    padding: 1rem;
}

.events-card-holder {
    display: flex;
    padding: 2rem;
}

.events-card {
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    box-shadow: 0px .8rem 1.6rem rgba(0, 0, 0, 0.25);
    background-color: white !important;
}

.card-header {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    padding: unset;
    margin: unset;
    font-size: 2.4rem;
    padding-bottom: 1rem;
    background-color: unset;
    border: unset;
}

.card-sub-header {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 1.4rem;
    line-height: 150%;
    text-transform: uppercase;
    padding: unset;
    padding-bottom: 2.5rem;

}

.intro-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

}

.intro-text-header {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 2rem;
    text-transform: capitalize;
    font-feature-settings: 'liga' off;
    color: #161616;
    padding-bottom: 1.5rem;
    padding-top: 5rem;
}

.intro-text {
    padding-top: 2.5rem;
    padding-left: 10rem;
    padding-right: 10rem;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    margin-bottom: 30px;
}

.FacilityEvent_main {
    width: 75vw !important;
    /* margin: auto; */
    padding: 40px !important;
    margin-bottom: 140px !important;
    display: flex;
    row-gap: 30px;
    flex-wrap: wrap;
    flex-direction: row;
}



.separator {
    width: 6.3rem;
    height: .8rem;
    background: #F31237;
}

.detail-label-text {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.detail-label {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 1.4rem;
    padding-left: 1rem;
}

.detail-icon-label-container {
    display: flex;
}

.detail-text {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
}

/* .FacilityEvent_name {

} */

.card_details_text {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #12101C;
    /* padding-left: 1rem; */
}

/* .detail-icon-business-hrs {
    background-image: url(../../../../public/assets/icons/briefcase.svg);
    width: 20px;
    height: auto;
} */

.icons {
    display: flex;
    margin-top: 10px;
    position: relative;
    margin-left: -5px;
}

.facebook {
    background: #1877F2;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    width: 32px;
    margin: 5px;
}

.twitter {
    background: #E60023;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    width: 32px;
    margin: 5px;
}

.header_new {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;

}

.text {
    font-family: 'Lato';
    /* font-style: normal; */
    font-weight: 700;
    font-size: 45px;
    line-height: 68px;
    text-align: center;
    color: #000000;
    margin-bottom: 20px !important;
    margin-top: 20px !important;
}

@media screen and (max-width: 900px) {
    .eventsFacilityBookingCard {
        padding: 10px 30px;
        flex-direction: column;

    }

    .facility_booking_map img {
        width: 100%;
    }

    .EventsFacilityBooking_card_one {
        width: 100%;
        margin-right: 40px;
    }

    .EventsFacilityBooking_card_two {
        width: 100%;
        margin-top: 10px;
    }

    .events_amenties_card {
        flex-direction: column;
    }

}

@media screen and (max-width: 600px) {
    .card-col {
        padding: 8.5rem 0rem;
    }

    .FacilityEvent {
        margin: 0px !important;
    }
    .FacilityEvent_main {
        padding: 0 !important;
        width: 90% !important;
    }


}