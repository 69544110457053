.memberprofilepage{
    width: 100%;
    /* height: 1000px; */
    height: 100rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.memberProfileInfo{
    display: flex;
    justify-content: flex-start;
    gap:20px;
}

.mobview{
    display: none;
}

.memberprofilepage .profilepagemain{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    /* height: 800px; */
    height: 80rem;
    /* width: 90%; */
    /* width: 1237px; */
    width: 123.7rem;
    background: #FFFFFF;
    /* box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15); */
    box-shadow: 0px .8rem 1.6rem rgba(0, 0, 0, 0.15);
    /* border-radius: 2px; */
    border-radius: .2rem;
    /* background-color: red; */
}

.memberprofilepage .profilepagemain .mobnavigation{
    display: none;
}

.memberprofilepage .profilepagemain .userinfo{
    margin-top: 20px;
    margin-left: 20px;
    height: 230px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.memberprofilepage .profilepagemain .userinfo div{
    display: flex;
    align-items: center;
    /* justify-content: center; */
}

.memberprofilepage .profilepagemain .userinfo div .username{
    /* margin-left: 25px; */
    margin-left: 2.5rem;
    /* height: 55px; */
    height: 5.5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}

.memberprofilepage .profilepagemain .userinfo div .username div:nth-child(1){
    /* width: 110px; */
    width: 11rem;
    /* height: 28px; */
    height: 2.8rem;
    
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    /* font-size: 20px; */
    font-size: 2rem;
    line-height: 140%;
    /* identical to box height, or 28px */
    
    
    /* Heading Text */
    
    color: #12101C;   
}

.memberprofilepage .profilepagemain .userinfo div .username div:nth-child(2){
    width: 58px;
    height: 20px;
    
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    /* font-size: 14px; */
    font-size: 1.4rem;
    line-height: 140%;
    /* identical to box height, or 20px */
    
    
    /* body text */
    
    color: #54595F;
}

.memberprofilepage .profilepagemain .userinfo .fname span:nth-child(1),.mobile span:nth-child(1), .email span:nth-child(1),.location span:nth-child(1){
    /* width: 73px; */
    width: 7.3rem;
    /* height: 20px; */
    height: 2rem;

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    /* font-size: 14px; */
    font-size: 1.4rem;
    line-height: 140%;
    /* identical to box height, or 20px */
    
    
    /* Heading Text */
    
    color: #12101C;    
}

.memberprofilepage .profilepagemain .userinfo .fname span:nth-child(2),.mobile span:nth-child(2),.email span:nth-child(2),.location span:nth-child(2){
    /* width: 7.1rem; */
    width: 20rem;
    /* height: 20px; */
    height: 2rem;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    /* font-size: 14px; */
    font-size: 1.4rem;
    line-height: 140%;
    /* identical to box height, or 20px */
    
    
    /* body text */
    
    color: #54595F;    
}

.memberprofilepage .profilepagemain .userinfo .mobile span:nth-child(1){
    /* width: 50px; */
    width: 5rem;
    /* height: 20px; */
    height: 2rem;
}

.memberprofilepage .profilepagemain .userinfo .mobile span:nth-child(2){
    /* width: 115px; */
    width: 11.5rem;
    /* height: 20px; */
    height: 2rem;
}

.memberprofilepage .profilepagemain .userinfo .email span:nth-child(1){
    width: 4.1rem;
    height: 2rem;
}

.memberprofilepage .profilepagemain .userinfo .email span:nth-child(2){
    width: 14.1rem;
    height: 2rem;
}

.memberprofilepage .profilepagemain .userinfo .location span:nth-child(1){
    width: 6.2rem;
    height: 2rem;
}

.memberprofilepage .profilepagemain .userinfo .location span:nth-child(2){
    width: 6.3rem;
    height: 2rem;
}

.memberprofilepage .profilepagemain .bottom{
    display: flex;
    justify-content: space-evenly;
}

.memberprofilepage .profilepagemain .bottom .history{
    height: 49rem;
    width: 69rem;
    background: #FFFFFF;
    border: .1rem solid #EDEBEB;
    border-radius: 1.2rem;
}

.memberprofilepage .profilepagemain .bottom .history .heading{
    margin-top: 2.6rem;
    margin-left: 1.2rem;
    margin-bottom: 2.6rem;
    width: 16.4rem;
    height: 2.2rem;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 140%;
    color: #12101C;    
}

.memberprofilepage .profilepagemain .bottom .history .package{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 34.4rem;
    width: 100%;
}

.memberprofilepage .profilepagemain .bottom .history .package .package1,.package2{
    height: 16.2rem;
    width: 65.5rem;
    background: #F8F9FA;
    border-radius: .8rem;
    display: flex;
    justify-content: space-around;
}

.memberprofilepage .profilepagemain .bottom .history .package .package1 .left{
    height: 100%;
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.memberprofilepage .profilepagemain .bottom .history .package .package1 .left div{
    width: 100%;
}

.memberprofilepage .profilepagemain .bottom .history .package .package1 .left .packhead{
    left: 3.66%;
    right: 71.6%;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 1.4rem;
    line-height: 140%;
    color: #12101C;   
}

.memberprofilepage .profilepagemain .bottom .history .package .package1 .left .membership span:nth-child(1),.eaddress span:nth-child(1),.memid span:nth-child(1),.amount span:nth-child(1){
    left: 3.66%;
    right: 84.89%;

    
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 140%;
    /* identical to box height, or 17px */
    
    
    /* body text */
    
    color: #54595F;
}
.memberprofilepage .profilepagemain .bottom .history .package .package1 .left .membership span:nth-child(2),.eaddress span:nth-child(2),.memid span:nth-child(2),.amount span:nth-child(2){
    left: 19.39%;
    right: 72.98%;

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 140%;
    /* identical to box height, or 17px */
    
    
    /* Heading Text */
    text-align: right;
    color: #12101C
}

.memberprofilepage .profilepagemain .bottom .history .package .package1 .right{
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-end;
    text-align: right;
}

.memberprofilepage .profilepagemain .bottom .history .package .package1 .right .appdue{
    display: flex;
    width: 13rem;
    justify-content: space-between;
}

.memberprofilepage .profilepagemain .bottom .history .package .package1 .right .appdue .approve{
    width: 5.7rem;
    height: 1.5rem;
    
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 1.5rem;
    /* identical to box height */
    
    text-align: justify;
    
    /* status/active */
    
    color: #00AB6F;    

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: .8rem;
    gap: 1rem;
    width: 7.4rem;
    background: #EDFFF9;
    border-radius: .6rem;    
}

.memberprofilepage .profilepagemain .bottom .history .package .package1 .right .appdue .due{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 1.5rem;
    /* identical to box height */

    text-align: justify;

    color: #FF1A1A;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* padding: 8px; */
    gap: 1rem;
    width: 4rem;
    background: #FFDFDF;
    border-radius: .6rem;
}

.memberprofilepage .profilepagemain .bottom .history .package .package1 .right button{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: .8rem 1.6rem;
    gap: 1rem;

    height: 3.3rem;
    /* secondary */
    
    border: .1rem solid #1E73BE;
    border-radius: .8rem;
    background: #F8F9FA;
    color: #1E73BE;
    outline: none;
}

.memberprofilepage .profilepagemain .bottom .history .package .package1 .right .remdays{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 1.4rem;
    color: #1E73BE;
}

/* Package 2 */


.memberprofilepage .profilepagemain .bottom .history .package .package2 .left{
    height: 100%;
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.memberprofilepage .profilepagemain .bottom .history .package .package2 .left div{
    width: 100%;
}

.memberprofilepage .profilepagemain .bottom .history .package .package2 .left .packhead{
    left: 3.66%;
    right: 71.6%;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 1.4rem;
    line-height: 140%;
    color: #12101C;   
}

.memberprofilepage .profilepagemain .bottom .history .package .package2 .left .membership span:nth-child(1),.eaddress span:nth-child(1),.memid span:nth-child(1),.amount span:nth-child(1){
    left: 3.66%;
    right: 84.89%;

    
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 140%;
    /* identical to box height, or 17px */
    
    
    /* body text */
    
    color: #54595F;
}
.memberprofilepage .profilepagemain .bottom .history .package .package2 .left .membership span:nth-child(2),.eaddress span:nth-child(2),.memid span:nth-child(2),.amount span:nth-child(2){
    left: 19.39%;
    right: 72.98%;

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 140%;
    /* identical to box height, or 17px */
    
    
    /* Heading Text */
    text-align: right;
    color: #12101C
}

.memberprofilepage .profilepagemain .bottom .history .package .package2 .right{
    width: 40%;
    display: flex;
    flex-direction: column;
    position: relative;
    top: 1rem;
    /* justify-content: space-evenly; */
    align-items: flex-end;
    text-align: right;
}

.memberprofilepage .profilepagemain .bottom .history .package .package2 .right .appdue{
    display: flex;
    width: 13rem;
    justify-content: space-between;
}

.memberprofilepage .profilepagemain .bottom .history .package .package2 .right .appdue .approve{
    width: 5.7rem;
    height: 1.5rem;
    
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 1.5rem;
    /* identical to box height */
    
    text-align: justify;
    
    /* status/active */
    
    color: #00AB6F;    

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: .8rem;
    gap: 1rem;
    width: 7.4rem;
    background: #EDFFF9;
    border-radius: .6rem; 
}

.memberprofilepage .profilepagemain .bottom .history .package .package2 .right .appdue .paid{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 1.5rem;
    /* identical to box height */

    text-align: justify;

    color: #00AB6F; 

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* padding: 8px; */
    gap: 1rem;
    width: 4rem;
    background: #EDFFF9;
    border-radius: .6rem;
}

.memberprofilepage .profilepagemain .bottom .payments{
    height: 49rem;
    width: 46.8rem;
    background: #FFFFFF;
    border: .1rem solid #EDEBEB;
    border-radius: 1.2rem;
}

.memberprofilepage .profilepagemain .bottom .payments .top{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.memberprofilepage .profilepagemain .bottom .payments .heading{
    margin-top: 2.6rem;
    margin-left: 1.2rem;
    margin-bottom: 2.6rem;
    width: 8rem;
    height: 2.2rem;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 140%;
    /* or 22px */
    
    
    /* Heading Text */
    
    color: #12101C;    
}

.memberprofilepage .profilepagemain .bottom .payments .top .calender-range{
    margin-right: 1.2rem;
    width: 14.5rem;
    display: flex;
    justify-content: space-between;
}

.memberprofilepage .profilepagemain .bottom .payments .top .calender-range img{
    
}

.memberprofilepage .profilepagemain .bottom .payments .payinfobox{
    display: flex;
    align-items: center;
    justify-content: center;
}

.memberprofilepage .profilepagemain .bottom .payments .payinfobox .payinfo{
    width: 44.5rem;
    height: 35rem;
    display: flex;
    justify-content: space-between;
}

.memberprofilepage .profilepagemain .bottom .payments .payinfobox .payinfo .eventsinfo{
    width: 48%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    /* background-color: saddlebrown; */
}

.memberprofilepage .profilepagemain .bottom .payments .payinfobox .payinfo .eventsinfo .box{
    width: 20rem;
    height: 4.2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* background-color: seagreen; */
}

.memberprofilepage .profilepagemain .bottom .payments .payinfobox .payinfo .eventsinfo .box .icon{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3.4rem;
    height: 3.4rem;
    border-radius: 50%;
    border: .1rem solid #F44334;
    /* transform: rotate(-180deg); */
}

.memberprofilepage .profilepagemain .bottom .payments .payinfobox .payinfo .eventsinfo .box.name-and-date{
    display: flex;
    flex-direction: column;
}

.memberprofilepage .profilepagemain .bottom .payments .payinfobox .payinfo .eventsinfo .box .name-and-date .eventname{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 140%;
    /* identical to box height, or 20px */
    
    
    /* Heading Text */
    
    color: #12101C;
}
.memberprofilepage .profilepagemain .bottom .payments .payinfobox .payinfo .eventsinfo .box .name-and-date .eventdate{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 140%;
    /* identical to box height, or 17px */
    
    
    /* body text */
    
    color: #54595F;
}

.memberprofilepage .profilepagemain .bottom .payments .payinfobox .payinfo .prices{
    width: 48%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.memberprofilepage .profilepagemain .bottom .payments .payinfobox .payinfo .prices .pr{
    width: 100%;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 1.4rem;
    line-height: 140%;
    /* identical to box height, or 20px */
    
    text-align: right;
    
    /* Linear/Red-Orange */
    
    color: #F44334;
}

.memberprofilepage{
    overflow-x: hidden;
}


@media screen and (max-width : 1285px){
    .memberprofilepage .profilepagemain .bottom .history{
        width: 55%;
    }

    .memberprofilepage .profilepagemain .bottom .history .package .package1, .package2{
        width: 90%;
    }

    .memberprofilepage .profilepagemain .bottom .payments{
        width: 40%;
    }
    
    .memberprofilepage .profilepagemain .bottom .payments .payinfobox .payinfo{
        width: 95%;
    } 

}

@media screen and (max-width : 1285px){
    .memberprofilepage .profilepagemain .bottom .payments .top .calender-range{
        /* display: block; */
        width: 145px;
    }

    .memberprofilepage .profilepagemain .bottom .payments .top .calender-range img{
        /* padding-left: 1rem; */
    }
}

@media screen and (max-width : 850px){
    .memberprofilepage .profilepagemain .bottom .history .package .package1 .left .packhead{
        position: relative;
        top: 44%;
        left: 0%;
    } 

    .memberprofilepage .profilepagemain .bottom .history .package .package1 .left div{
        display: flex;
        flex-direction: column;
    }

    .memberprofilepage .profilepagemain .bottom .history .package .package1 .left .membership{
        position: relative;
        top: 55%;
    }

    .memberprofilepage .profilepagemain .bottom .history .package .package1 .left .eaddress{
        position: relative;
        top: 16%;
        left: 82%;
    }

    .memberprofilepage .profilepagemain .bottom .history .package .package1 .left .memid{
        position: relative;
        bottom: -14%;
    }

    .memberprofilepage .profilepagemain .bottom .history .package .package1 .left .amount{
        position: relative;
        top: -25%;
        left: 84%;
    }

    .memberprofilepage .profilepagemain .bottom .history .package .package1 .left .membership span:nth-child(2), .eaddress span:nth-child(2), .memid span:nth-child(2), .amount span:nth-child(2){
        text-align: left;
        position: relative;
        left: 0%;
        bottom: 36%;
    }

    /* package2 */

    .memberprofilepage .profilepagemain .bottom .history .package .package2 .left .packhead{
        position: relative;
        top: 44%;
        left: 0%;
    } 

    .memberprofilepage .profilepagemain .bottom .history .package .package2 .left div{
        display: flex;
        flex-direction: column;
    }

    .memberprofilepage .profilepagemain .bottom .history .package .package2 .left .membership{
        position: relative;
        top: 55%;
    }

    .memberprofilepage .profilepagemain .bottom .history .package .package2 .left .eaddress{
        position: relative;
        top: 16%;
        left: 82%;
    }

    .memberprofilepage .profilepagemain .bottom .history .package .package2 .left .memid{
        position: relative;
        bottom: -14%;
    }

    .memberprofilepage .profilepagemain .bottom .history .package .package2 .left .amount{
        position: relative;
        top: -25%;
        left: 84%;
    }

    .memberprofilepage .profilepagemain .bottom .history .package .package2 .left .membership span:nth-child(2), .eaddress span:nth-child(2), .memid span:nth-child(2), .amount span:nth-child(2){
        text-align: left;
        position: relative;
        left: 0%;
        bottom: 36%;
    }
}

@media screen and (max-width : 600px){

    .mobview{
        display: block;
    }

    .tabs{
        width: 100%;
    }

    .memberprofilepage{
        /* height: 114rem; */
        height: 129rem;
    }

    .memberprofilepage .profilepagemain{
        /* height: 93rem; */
        height: 108rem;
    }

    .memberprofilepage .profilepagemain .mobnavigation{
        display: flex;
        justify-content: space-around;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .memberprofilepage .profilepagemain .mobnavigation a{
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 140%;
        /* identical to box height, or 20px */
        
        
        /* body text */
        
        color: #54595F;    
    }

    .memberprofilepage .profilepagemain .bottom{
        height: 90rem;
    }

    .memberprofilepage .profilepagemain .bottom .history{
        height: 61rem;
        /* width: 80%; */
        width: 100%;
        /* border: none; */
    }

    .memberprofilepage .profilepagemain .bottom .history .package{
        height: 51.4rem;
    }

    .memberprofilepage .profilepagemain .bottom .hide{
        display: none;
    }

    .memberprofilepage .profilepagemain .bottom .history .package .package1{
        flex-direction: column;
        height: 24.5rem;
    }

    .memberprofilepage .profilepagemain .bottom .history .package .package1 .left{
        height: 45%;
        margin-left: 1rem;
    }

    .memberprofilepage .profilepagemain .bottom .history .package .package1 .left .packhead{
        position: relative;
        top: 66%;
        width: 120px;
    }    

    .memberprofilepage .profilepagemain .bottom .history .package .package1 .left .membership{
        position: relative;
        top: 73%;
    }

    .memberprofilepage .profilepagemain .bottom .history .package .package1 .left .eaddress {
        position: relative;
        top: 30%;
        left: 160%;
    }

    .memberprofilepage .profilepagemain .bottom .history .package .package1 .left .memid {
        position: relative;
        bottom: -28%;
    }

    .memberprofilepage .profilepagemain .bottom .history .package .package1 .left .amount {
        position: relative;
        top: -17%;
        left: 160%;
    }
    
    .memberprofilepage .profilepagemain .bottom .history .package .package1 .right{
        flex-direction: row;
        align-items: flex-start;
        width: 100%;
    }

    .memberprofilepage .profilepagemain .bottom .history .package .package1 .right .paynow{
        position: relative;
        top: -40%;
        right: -25%;
    }

    .memberprofilepage .profilepagemain .bottom .history .package .package1 .right .appdue{
        position: relative;
        /* left: 0%; */
    }

    .memberprofilepage .profilepagemain .bottom .history .package .package1 .right .remdays{
        position: relative;
        top: 80%;
    }

    /* package2 */

    .memberprofilepage .profilepagemain .bottom .history .package .package2{
        flex-direction: column;
        height: 24.5rem;
    }

    .memberprofilepage .profilepagemain .bottom .history .package .package2 .left{
        height: 45%;
        margin-left: 1rem;
    }

    .memberprofilepage .profilepagemain .bottom .history .package .package2 .left .packhead{
        position: relative;
        top: 66%;
        width: 120px;
    }    

    .memberprofilepage .profilepagemain .bottom .history .package .package2 .left .membership{
        position: relative;
        top: 73%;
    }

    .memberprofilepage .profilepagemain .bottom .history .package .package2 .left .eaddress {
        position: relative;
        top: 30%;
        left: 160%;
    }

    .memberprofilepage .profilepagemain .bottom .history .package .package2 .left .memid {
        position: relative;
        bottom: -28%;
    }

    .memberprofilepage .profilepagemain .bottom .history .package .package2 .left .amount {
        position: relative;
        top: -17%;
        left: 160%;
    }
    
    .memberprofilepage .profilepagemain .bottom .history .package .package2 .right{
        flex-direction: row;
        align-items: flex-start;
        width: 100%;
    }

    .memberprofilepage .profilepagemain .bottom .history .package .package2 .right .paynow{
        position: relative;
        top: -40%;
        right: -25%;
    }

    .memberprofilepage .profilepagemain .bottom .history .package .package2 .right .remdays{
        position: relative;
        top: 80%;
    }

    .memberprofilepage .profilepagemain .bottom .payments{
        /* width: 80%; */
        width: 100%;
        /* border: none; */
    }
    
}