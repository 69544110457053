.mainupcoming{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 3;
    margin-top: 30px;
    margin-bottom: 60px;
    margin: auto;
}

.eventBoxContainer{
    width: 33%;
}
.eventBoxContainer  img{
    width: 100%;
}

.header {
    font-family: Open Sans;
    font-size: 42px;
    font-weight: 800;
    line-height: 76px;
    margin-bottom: 18px;
    
}

.events{
    justify-content: center;
    align-items: center;
    display: flex;
    margin-top:45px;
}
.image_Overlay{
    position: absolute;
    top:26px;
    z-index: 999;
    bottom: 26px;
    left:26px;
    right: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #F31237;
}

.event-1 , .event-2 , .event-3{
    margin: 7px;
}


.desc{
    position: absolute;
    text-align: center;
    top:340px;
    left:33px;
    right: 33px;
}

.calendar{
    background-color: #F31237;
    border-radius: 8px;
    text-align: center;
    font-family: 'Open Sans';
    font-style: normal;
    color: #FFFFFF;
    /* margin-right: 30px; */
    /* padding: 3px 0 8px 0; */
    padding: 3px 8px;
    align-self: flex-start;
}

.month{
    font-weight: 700;
    font-size: 11.3717px;
    line-height: 15px;
}

.date{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
}
.descriptions{
    font-family: 'Open Sans';
    font-style: normal;
    width: auto;
    color: #12101C;
    align-self: flex-start;
}

.first{
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 20px;
}

.descriptions .first{
    color: #FFFFFF;
}

.second_desc{
    width: 220px;
}
.eventsImage{
    object-fit:cover;
    width:20vw;
    height:583px;
}

@media only screen and (max-width: 960px) {
    .header{
        text-align: center;
    }
    .events {
        flex-direction: column;
    }
    .eventsImage{
        width: 80vw;
    }
    
    .event-1,.event-2,.event-3 {
        margin-bottom: 50px;
    }
    .eventBoxContainer{
        width: 100%;
    }
    .eventBoxContainer  img{
        width: 100%;
    }
    .descriptions{
        width: fit-content;
    }

    .first{
        text-align: center !important;
    }

    .second{
        text-align: center !important;
    }
  
}